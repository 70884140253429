import { AnimatePresence, motion } from 'framer-motion'
import { ExhibitorPreview, ExhibitorStory } from '~/features/exhibitors'

import * as styles from './SearchResults.css'

type Props = {
  isSearchActive?: boolean
  exhibitors: ExhibitorStory[]
}

const itemsMotion = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
}

const itemMotion = {
  hidden: { opacity: 0 },
  show: { opacity: 1 },
}

export function SearchResults({ isSearchActive, exhibitors }: Props) {
  return (
    <AnimatePresence>
      {isSearchActive && (
        <div css={styles.results}>
          <div css={styles.resultsBox}>
            <div css={styles.resultsCount}>
              {exhibitors.length} {exhibitors.length === 1 ? 'Ergebnis' : 'Ergebnisse'}
            </div>
            <motion.div
              css={styles.resultsItems}
              variants={itemsMotion}
              initial="hidden"
              animate={isSearchActive ? 'show' : 'hidden'}
            >
              {exhibitors.map((exhibitor) => (
                <motion.div css={styles.resultsItem} variants={itemMotion} key={exhibitor.id}>
                  <ExhibitorPreview exhibitor={exhibitor} />
                </motion.div>
              ))}
            </motion.div>
          </div>
        </div>
      )}
    </AnimatePresence>
  )
}
