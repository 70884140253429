import * as styles from './ResetButton.css'

export function ResetButton({ onClick, ...props }) {
  return (
    <button css={styles.button} onClick={onClick} type="button" {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.07 19.07" css={styles.icon}>
        <g data-name="Gruppe 193" transform="translate(-1751.948 -71.121)" fill="none" stroke="#d2281b" strokeWidth={2}>
          <line data-name="Linie 134" x2="24.969" transform="rotate(45 789.623 2151.556)" />
          <line data-name="Linie 135" x2="24.969" transform="rotate(135 870.28 402.557)" />
        </g>
      </svg>
    </button>
  )
}
