import { motion } from 'framer-motion'
import Image from 'next/legacy/image'
import { useInView } from 'react-intersection-observer'
import { CTA, Container, HandRightIcon, RichText, Title } from '~/components'
import { ImpressionStory } from '~/features/impressions'
import { resizeImage } from '~/storyblok/resizeImage'
import { getRandomImages } from './getRandomImages'

import * as styles from './Gallery.css'

type Props = {
  reverse?: boolean
  impression: ImpressionStory
}

const itemsMotion = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.15,
    },
  },
}

const itemMotion = {
  hidden: { opacity: 0 },
  show: { opacity: 1 },
}

export function Gallery({ reverse = false, impression }: Props) {
  const { ref, inView } = useInView()

  const images = getRandomImages(impression.content.gallery).map((image) =>
    resizeImage(image, { width: 800, height: 800 })
  )

  return (
    <div css={styles.wrapper}>
      <Container>
        <div css={[styles.content, reverse && styles.contentReverse]}>
          <div css={styles.media} ref={ref}>
            <div css={styles.canvas}>
              <motion.div
                css={styles.images}
                variants={itemsMotion}
                initial="hidden"
                animate={inView ? 'show' : 'hidden'}
              >
                {images.map((image) => (
                  <motion.div css={styles.item} key={image.id} variants={itemMotion}>
                    <Image src={image.url} css={styles.image} alt={image.alt || ''} key={image.id} layout="fill" />
                  </motion.div>
                ))}
              </motion.div>
            </div>
          </div>
          <div css={styles.main}>
            <Title color label="Impressionen">
              {impression.content.title}
            </Title>
            <RichText document={impression.content.text} />
            <div css={styles.cta}>
              <CTA href={`/impressionen/${impression.slug}`} icon={<HandRightIcon css={styles.ctaIcon} />}>
                zu den Bildern
              </CTA>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
