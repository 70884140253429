import { CTA, HandWaveIcon, Link } from '~/components'

import * as styles from './Layout.css'

type Props = {
  children: React.ReactNode
}

export function Layout({ children }: Props) {
  return (
    <div css={styles.layout}>
      <div css={styles.layoutContent}>{children}</div>
      <div css={styles.layoutFooter}>
        <footer css={styles.footer}>
          <div css={styles.footerContent}>
            <div css={styles.footerMain}>
              <CTA
                href="https://www.next-step.li/"
                target="_blank"
                rel="noopener noreferrer"
                icon={<HandWaveIcon css={styles.footerIcon} />}
              >
                zu next-step.li
              </CTA>
            </div>
            <div css={styles.footerMeta}>
              <Link href="/impressum">Impressum</Link> &nbsp;|&nbsp; <Link href="/datenschutz">Datenschutz</Link>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}
