import { Title, Video } from '~/components'

export function Review() {
  return (
    <div>
      <Title label="Rückblick" spacing="xlarge">
        Das war 2024!
      </Title>
      <Video src="https://a.storyblok.com/f/123006/x/36c6f94dee/20240927_digicube_nextstep.mp4" />
    </div>
  )
}
