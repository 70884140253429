import { css } from '@emotion/react'
import { breakpoints, container, rack, rackContent, radius } from '~/base'

/** Plan */

export const plan = css`
  @media ${breakpoints.large} {
    display: flex;
  }
`

export const planIntro = css`
  @media ${breakpoints.large} {
    width: 336px;
    position: relative;
  }
`

export const planMain = css`
  width: 100%;

  @media ${breakpoints.large} {
    margin: 0;
    position: relative;
    z-index: 0;
  }
`

export const planPdf = css`
  display: none;

  @media ${breakpoints.large} {
    display: block;
  }
`

/** Exhibitors */

export const exhibitors = css`
  ${rack({ color: 'meta', content: true })}
  ${radius('xxlarge', true)}
`

export const exhibitorsContent = css`
  ${rackContent()}
  padding-top: 48px;
  padding-bottom: 48px;

  @media ${breakpoints.large} {
    padding-top: 106px;
    padding-bottom: 106px;
  }
`

export const exhibitorsHeader = css`
  @media ${breakpoints.medium} {
    ${container({ width: 'small' })}
    margin-left: auto;
    margin-right: auto;
  }
`
