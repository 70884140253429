import { css } from '@emotion/react'
import { breakpoints, container, font } from '~/base'

export const wrapper = css`
  position: relative;
  overflow: hidden;

  @media ${breakpoints.large} {
    &[data-version='male'] {
      // color: #fff;
    }

    &::after {
      content: '';
      display: block;
      top: 0;
      right: 0;
      bottom: 0;
      left: 50%;
      position: absolute;
      z-index: -1;
      background: repeat-x;
    }

    &[data-version='male']::after {
      background-image: url('/intro_m_repeat.jpg');
    }

    &[data-version='female']::after {
      background-image: url('/intro_f_repeat.jpg');
    }
  }
`

export const intro = css`
  background: 90% bottom no-repeat;
  background-size: cover;
  min-height: 688px;
  padding-top: 24px;
  padding-bottom: 24px;
  width: 1920px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  &[data-version='male'] {
    background-image: url('/intro_m_mobile.jpg');
    background-position: top center;
  }

  &[data-version='female'] {
    background-image: url('/intro_f_mobile.jpg');
    background-position: top center;
  }

  @media ${breakpoints.medium} {
    &[data-version='male'] {
      background-image: url('/intro_m.jpg');
      background-position: 90% bottom;
    }

    &[data-version='female'] {
      background-image: url('/intro_f.jpg');
      background-position: 90% bottom;
    }
  }

  @media ${breakpoints.large} {
    min-height: 888px;
    padding-top: 100px;
    border-bottom-left-radius: 200px;
    // background-position: bottom right;
    color: #fff;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 100%;
      bottom: 0;
      width: 400px;
      background: red;
      background: no-repeat;
    }

    &[data-version='male']::after {
      background-image: url('/intro_m_sham.jpg');
    }

    &[data-version='female']::after {
      background-image: url('/intro_f_sham.jpg');
    }
  }
`

export const introContent = css`
  ${container({ padding: true })}
  margin-left: auto;
  margin-right: auto;
`

export const brand = css`
  margin-bottom: 160px;

  @media ${breakpoints.large} {
    margin-bottom: 15px;
  }

  @media (min-width: 1740px) {
    margin-left: -130px;
  }
`

export const logo = css`
  display: block;
  height: 80px;

  @media ${breakpoints.medium} {
    height: 140px;
  }

  @media ${breakpoints.large} {
    height: 208px;
  }
`

export const main = css`
  background-color: rgba(255, 255, 255, 0.8);
  padding: 24px;
  border-top-right-radius: 40px;
  border-bottom-left-radius: 40px;

  @media ${breakpoints.large} {
    all: unset;
  }
`

export const title = css`
  margin: 0 0 48px;
  padding: 0;
`

export const day = css`
  margin: 0 0 8px 0;
`

export const columns = css`
  display: flex;
  flex-wrap: wrap;
  max-width: 680px;
  margin-bottom: 40px;
  margin: -12px -24px;
  margin-bottom: 24px;
`

export const column = css`
  flex: 1;
  flex: auto;
  padding: 12px 24px;
`

/** Location */

export const location = css`
  display: flex;
  align-items: center;
  ${font({ size: 'large' })}
  line-height: 1.1;
  text-decoration: none;
`

export const locationIcon = css`
  height: 40px;
  margin-right: 18px;
`

/** Badge */

export const badge = css`
  position: absolute;
  bottom: 10px;
  right: 20px;
  height: 140px;
  width: auto;
  display: none;

  @media (min-width: 420px) {
    display: block;
  }

  @media (min-width: 560px) {
    bottom: 60px;
    right: 10%;
    height: 180px;
  }

  @media (min-width: 1024px) {
    height: 250px;
    right: 36%;
    bottom: 100px;
  }

  @media (min-width: 1440px) {
    height: 288px;
    right: 33%;
  }

  @media (min-width: 1920px) {
    right: 28%;
  }
`
