import { css } from '@emotion/react'
import { breakpoints } from '~/base'

export const wrapper = css``

export const content = css`
  display: flex;
  align-items: center;
  margin: -24px;
  flex-direction: column;

  @media ${breakpoints.large} {
    flex-direction: row;
  }
`

export const contentReverse = css`
  @media ${breakpoints.large} {
    flex-direction: row-reverse;
  }
`

const columns = css`
  flex: 1;
  padding: 24px;

  @media ${breakpoints.large} {
    flex: auto;
  }
`

export const media = css`
  ${columns}
  width: 100%;

  @media ${breakpoints.large} {
    width: 58.333333%;
  }
`

export const main = css`
  ${columns}

  @media ${breakpoints.large} {
    margin-left: 48px;
    width: 41.666666%;
  }
`

export const canvas = css`
  position: relative;
  padding-bottom: 75%;
  display: grid;

  > div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
`

export const images = css`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: var(--gap);
  grid-auto-flow: row;
  grid-template-areas:
    'Image1 Image1 Image1 Image1 Image1 Image2 Image2 Image2 . .'
    'Image1 Image1 Image1 Image1 Image1 Image3 Image3 Image3 Image3 Image3'
    'Image1 Image1 Image1 Image1 Image1 Image3 Image3 Image3 Image3 Image3';
`

export const item = css`
  border-top-right-radius: 40px;
  border-bottom-left-radius: 40px;
  overflow: hidden;
  position: relative;

  &:nth-of-type(1) {
    grid-area: Image1;
  }

  &:nth-of-type(2) {
    grid-area: Image2;
  }

  &:nth-of-type(3) {
    grid-area: Image3;
  }
`

export const image = css`
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
`

export const cta = css`
  color: var(--colors-primary);
  margin-top: 60px;
`

export const ctaIcon = css`
  height: 38px;
  position: relative;
  top: 5px;
`
