import { css } from '@emotion/react'
import { breakpoints, container, font } from '~/base'

export const wrapper = css`
  --input-height: 54px;

  @media ${breakpoints.large} {
    --input-height: 64px;
  }
`

export const search = css`
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;

  @media ${breakpoints.large} {
    ${container({ padding: true })}
  }
`

export const searchContainer = css`
  padding: var(--container-padding);
  background: var(--colors-primary);
  color: #fff;

  @media ${breakpoints.large} {
    border-radius: 100px;
    margin: -73px 0 0 0;
  }
`

export const searchItems = css`
  margin: -6px -12px;

  @media ${breakpoints.medium} {
    display: flex;
    margin: -12px;
  }
`

export const searchItem = css`
  padding: 6px 12px;

  @media ${breakpoints.medium} {
    flex: auto;
    padding: 6px 20px;
  }
`

export const searchItemMeta = css`
  @media ${breakpoints.medium} {
    max-width: 400px;
  }
`

export const searchItemLast = css`
  flex: 0 !important;
`

export const searchField = css`
  position: relative;
`

export const searchIcon = css`
  position: absolute;
  top: 50%;
  left: 1em;
  color: #a0a0a0;
  height: 1em;
  transform: translateY(-44%);
`

export const searchReset = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: calc(1rem - 3px);
`

export const searchInput = css`
  ${font({ size: 'small' })}
  font-family: inherit;
  font-weight: inherit;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 50px;
  height: var(--input-height);
  padding: 0 1em;
  width: 100%;
  transition: box-shadow 0.1s ease;
  will-change: box-shadow;
  padding-left: calc(1em + 30px);

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 2px var(--colors-primary), 0 0 0 3px white;
  }

  &::placeholder {
    color: #a0a0a0;
    opacity: 1 !important; // needed in Chrome
  }

  @media ${breakpoints.large} {
    ${font({ size: 'medium' })}
  }
`

export const searchButton = css`
  all: unset;
  cursor: pointer;
  width: var(--input-height);
  height: var(--input-height);
  background: #fff;
  border-radius: 50px;
  color: var(--colors-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;

  &:hover {
    > svg {
      transform: scale(1.2);
    }
  }
`

export const select = css`
  // A reset of styles, including removing the default dropdown arrow
  appearance: none;
  // Additional resets for further consistency

  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;

  ${searchInput}
`

export const selectInactive = css`
  color: #a0a0a0;
`

/** Categories */

export const categories = css`
  position: relative;
  height: 100%;
  background: #fff;
  color: #000;
  border-radius: 100px;
  cursor: pointer;
  border-radius: 100px;
  transition: box-shadow 0.1s ease;
  will-change: box-shadow;
`

export const categoriesActive = css`
  box-shadow: 0 0 0 2px var(--colors-primary), 0 0 0 3px white;
`

export const categoriesButton = css`
  ${font({ size: 'small' })}
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 1.25rem;
  color: #a0a0a0;
  position: relative;
  z-index: 2;
  user-select: none;
  height: var(--input-height);

  @media ${breakpoints.large} {
    ${font({ size: 'medium' })}
  }
`

export const categoriesButtonIcon = css`
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.25s ease;
  transform-origin: center;
  pointer-events: none;
  right: 1rem;
  height: 10px;
  width: auto;
`

export const categoriesButtonIconActive = css`
  transform: translateY(-50%) scale(-1, -1);
`

export const categoriesList = css`
  ${font({ size: 'small' })}
  margin: 0;
  padding: 60px 0 0.75rem 0;
  list-style: none;
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  z-index: 1;
  background: #fff;
  border-radius: 40px;
  color: #000000;
  box-shadow: 1px 5px 5px 0 rgba(0, 0, 0, 0.2);
`

export const categoriesItem = css`
  padding: 4px 1.25rem;
  display: flex;
  cursor: pointer;
  user-select: none;
  color: #a0a0a0;
`

export const categoriesItemActive = css`
  color: #000;
`

export const categoriesIcon = css`
  display: block;
  height: 16px;
  margin-right: 12px;
  margin-top: 4px;
`

export const categoriesReset = css`
  padding: 14px 1.5rem 8px 1rem;
  text-decoration: underline;
  ${font({ size: 'xsmall' })}

  text-align: right;
`
