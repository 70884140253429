import { AnimateInView, Heading, LocationIcon, Logo, Text } from '~/components'
import { useVersion } from './useVersion'

import * as styles from './Intro.css'

export function Intro() {
  const version = useVersion()

  return (
    <div css={styles.wrapper} data-version={version}>
      <div css={styles.intro} data-version={version}>
        <div css={styles.introContent}>
          <div css={styles.brand}>
            <Logo css={styles.logo} />
          </div>
          <div css={styles.main}>
            <img src={version === 'male' ? `/intro_m_badge.svg` : `/intro_f_badge.svg`} css={styles.badge} />
            <Heading size="xxlarge" level="h1" css={styles.title}>
              10. Berufs- &<br /> Bildungs&shy;tage
            </Heading>
            <div css={styles.columns}>
              <div css={styles.column}>
                <AnimateInView>
                  <Heading size="large" level="h2" weight="regular" css={styles.day}>
                    27./28. September 2024
                  </Heading>
                </AnimateInView>
              </div>
              {/*
              <div css={styles.column}>
                <AnimateInView>
                  <Heading size="large" level="h2" weight="regular" css={styles.day}>
                    Fr 23.09.22
                  </Heading>
                  08.30 – 12.00 Uhr,
                  <br />
                  13.00 – 18.00 Uhr
                </AnimateInView>
              </div>
              <div css={styles.column}>
                <AnimateInView delay={200}>
                  <Heading size="large" level="h2" weight="regular" css={styles.day}>
                    Sa 24.09.22
                  </Heading>
                  09.00 – 14.00 Uhr
                </AnimateInView>
              </div>
              */}
            </div>

            <AnimateInView delay={300}>
              <a
                href="https://goo.gl/maps/d7RPMZriUbFFJUVo9"
                target="_blank"
                rel="noopener noreferrer"
                css={styles.location}
              >
                <LocationIcon css={styles.locationIcon} /> <Text size="large">SAL – Schaan</Text>
              </a>
            </AnimateInView>
          </div>
        </div>
      </div>
    </div>
  )
}
