import { css } from '@emotion/react'
import { breakpoints, font, rack, rackContent, radius } from '~/base'

/** Layout */

export const layout = css`
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const layoutContent = css`
  flex: 1;
`

export const layoutFooter = css``

/** Footer */

export const footer = css`
  ${rack({ width: 'medium', color: 'primary', content: true })}
  ${radius('medium', true)}
`

export const footerContent = css`
  ${rackContent({ width: 'medium' })}
  padding-top: 20px;
  padding-bottom: 16px;

  @media ${breakpoints.medium} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media ${breakpoints.large} {
    padding-top: 34px;
    padding-bottom: 26px;
  }
`

export const footerMain = css`
  margin-bottom: 24px;

  @media ${breakpoints.medium} {
    margin-bottom: 0;
  }
`

export const footerMeta = css`
  ${font({ size: 'xsmall' })}
  margin-top: -10px;

  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`

export const footerIcon = css`
  height: 60px;
  position: relative;
  top: 3px;
`
