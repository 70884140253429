import { useEffect, useRef, useState } from 'react'
import { AnimateInView, SearchIcon } from '~/components'
import { useCategories, useExhibitors } from '~/features/exhibitors'
import { CheckboxCheckedIcon } from './CheckboxCheckedIcon'
import { CheckboxUncheckedIcon } from './CheckboxUncheckedIcon'
import { ResetButton } from './ResetButton'
import { SearchResults } from './SearchResults'

import * as styles from './Search.css'

type SearchType = {
  keywords: string
  categories: any[]
}

const initialSearch = {
  keywords: '',
  categories: [],
}

export function Search() {
  const ref = useRef<any>()
  const [search, setSearch] = useState<SearchType>(initialSearch)
  const [keywords, setKeywords] = useState('')
  const [categories, setCategories] = useState<any>([])
  const [showCategories, setShowCategories] = useState(false)

  const exhibitors = useExhibitors({ search })
  const allCategories = useCategories()

  useEffect(() => {
    /** Check if clicked outside of element */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowCategories(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  function onSearch(e) {
    e.preventDefault()
    setSearch({
      ...search,
      keywords,
    })
  }

  function onReset() {
    setKeywords('')
    setSearch({ keywords: '', categories })
  }

  function onResetCategories() {
    setCategories([])
    setSearch({ keywords, categories: [] })
    setShowCategories(false)
  }

  function onChangeKeywords(e) {
    setKeywords(e.target.value)
    // TODO: use debounce for performance
    onSearch(e)
  }

  function onChangeCategories(category) {
    let newCategories
    if (categories.includes(category)) {
      // remove
      newCategories = categories.filter((cat) => cat !== category)
    } else {
      // add
      newCategories = [...categories, category]
    }

    setCategories(newCategories)

    setSearch({
      ...search,
      categories: newCategories,
    })
  }

  function onToggleShowCategories() {
    setShowCategories((value) => !value)
  }

  const isSearchActive = (search.keywords && true) || search.categories.length > 0

  return (
    <div css={styles.wrapper}>
      <AnimateInView delay={600}>
        <div css={styles.search}>
          <div css={styles.searchContainer}>
            <form onSubmit={onSearch}>
              <div css={styles.searchItems}>
                <div css={styles.searchItem}>
                  <div css={styles.searchField}>
                    <SearchIcon css={styles.searchIcon} />
                    <input
                      type="text"
                      placeholder="Aussteller:in oder Lehrberuf"
                      css={styles.searchInput}
                      value={keywords}
                      onChange={onChangeKeywords}
                    />
                    {keywords && <ResetButton onClick={onReset} css={styles.searchReset} />}
                  </div>
                </div>
                <div css={[styles.searchItem, styles.searchItemMeta]}>
                  <div css={[styles.categories, showCategories && styles.categoriesActive]} ref={ref}>
                    <div css={styles.categoriesButton} onClick={onToggleShowCategories}>
                      {categories.length > 0 ? (
                        <>
                          {categories.length} Branche{categories.length > 1 ? 'n' : ''} gewählt
                        </>
                      ) : (
                        <>Branche wählen</>
                      )}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 11 6.402"
                        css={[styles.categoriesButtonIcon, showCategories && styles.categoriesButtonIconActive]}
                      >
                        <path
                          d="M5.156 6.273h-.043a.628.628 0 00.387.13.628.628 0 00.387-.13l4.984-5.07A.465.465 0 0011 .86a.628.628 0 00-.129-.387l-.344-.3a.465.465 0 00-.344-.129.628.628 0 00-.383.129L5.5 4.555 1.2.173A.534.534 0 00.813.001a.408.408 0 00-.344.172l-.34.3A.628.628 0 000 .86a.408.408 0 00.172.344z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                    {showCategories && (
                      <ul css={styles.categoriesList}>
                        {allCategories?.map((category) => (
                          <li
                            key={category}
                            css={[styles.categoriesItem, categories.includes(category) && styles.categoriesItemActive]}
                            onClick={() => onChangeCategories(category)}
                          >
                            {categories.includes(category) ? (
                              <CheckboxCheckedIcon css={styles.categoriesIcon} />
                            ) : (
                              <CheckboxUncheckedIcon css={styles.categoriesIcon} />
                            )}
                            {category}
                          </li>
                        ))}
                        <li css={styles.categoriesReset} onClick={onResetCategories}>
                          Filter zurücksetzen
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
                {/*
                <div css={[styles.searchItem, styles.searchItemLast]}>
                  <button type="submit" css={styles.searchButton}>
                    <SearchIcon css={styles.searchIcon} />
                  </button>
                </div>
                */}
              </div>
            </form>
          </div>
        </div>
        <SearchResults isSearchActive={isSearchActive} exhibitors={exhibitors} />
      </AnimateInView>
    </div>
  )
}
