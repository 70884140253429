import { css } from '@emotion/react'
import { breakpoints, container, font, radius } from '~/base'

export const results = css`
  margin-left: auto;
  margin-right: auto;

  @media ${breakpoints.large} {
    ${container({ padding: true })}
  }
`

export const resultsBox = css`
  background: #f5f4f4;
  padding: var(--container-padding);
  position: relative;
  z-index: 0;
  ${radius('medium')}
  border-top-right-radius: 0;
  border-bottom-right-radius: var(--border-radius);

  @media ${breakpoints.large} {
    margin-top: -73px;
    padding-top: calc(73px + var(--container-padding));
  }
`

export const resultsCount = css`
  ${font({ size: 'small', weight: 'bold' })}
  color: #A0A0A0;
  margin-top: calc(-1 * var(--container-padding) / 2.5);
  margin-bottom: calc(var(--container-padding) / 1.5);
  position: relative;
  z-index: 1;
`

export const resultsItems = css`
  display: flex;
  flex-wrap: wrap;
  margin: calc(-1 * var(--container-padding) / 2);
`

export const resultsItem = css`
  padding: calc(var(--container-padding) / 2);
  flex-basis: 33.333333%;
`
