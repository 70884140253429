import { sample } from 'lodash'
import { useEffect, useState } from 'react'

type Version = 'male' | 'female'

const versions: Version[] = ['male', 'female']

function getRandomVersion(exclude?: Version): Version {
  let availableVersions = [...versions]

  if (exclude) {
    // Exclude a version from available versions.
    availableVersions = availableVersions.filter((version) => version !== exclude)
  }

  return sample(availableVersions) || 'male'
}

export function useVersion() {
  const [version, setVersion] = useState<Version>()

  useEffect(() => {
    const lastVersion = localStorage.getItem('intro-version')
    const newVersion: Version = lastVersion ? getRandomVersion(lastVersion as Version) : getRandomVersion()

    localStorage.setItem('intro-version', newVersion)
    setVersion(newVersion)
  }, [])

  return version
}
