import { css } from '@emotion/react'

export const button = css`
  background: transparent;
  border: 0;
  outline: none;
  z-index: 1;
  cursor: pointer;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 20px;
  right: 18px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const icon = css`
  display: block;
  height: 18px;
`
