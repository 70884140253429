import Head from 'next/head'
import {
  CategoriesProvider,
  ExhibitorCategory,
  ExhibitorStory,
  ExhibitorsProvider,
  fetchExhibitors,
} from '~/features/exhibitors'
import { fetchCategories } from '~/features/exhibitors/fetchCategories'
import { Home } from '~/features/home/Home'
import { ImpressionStory, fetchImpressions } from '~/features/impressions'
import { fetchPolls } from '~/features/polls/fetchPolls'
import { Poll } from '~/features/polls/types'

type Props = {
  exhibitors: ExhibitorStory[]
  categories: ExhibitorCategory[]
  impressions: ImpressionStory[]
  polls: Poll[]
}

export default function HomePage({ exhibitors, categories, impressions, polls }: Props) {
  return (
    <ExhibitorsProvider exhibitors={exhibitors}>
      <CategoriesProvider categories={categories}>
        <Head>
          <meta property="og:url" content={`https://digital.next-step.li/`} />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content="10. Berufs- & Bildungstage 2024 | 27./28. September 2024 | Next-Step – Bildung. Beruf. Liechtenstein."
          />
          <meta property="og:image" content="https://digital.next-step.li/share.jpg" />
        </Head>
        <Home impressions={impressions} polls={polls} />
      </CategoriesProvider>
    </ExhibitorsProvider>
  )
}

export async function getStaticProps({ preview = false }) {
  const exhibitors = (await fetchExhibitors(preview)) || []
  const categories = (await fetchCategories()) || []
  const impressions = (await fetchImpressions()) || []
  const polls = await fetchPolls()

  return {
    props: { exhibitors, categories, preview, impressions, polls },
  }
}
