import clsx from 'clsx'
import Image from 'next/image'
import { useEffect, useState } from 'react'
import { Poll } from '~/features/polls/types'

import styles from './Polls.module.scss'

import pollThemeA from './assets/polls_theme_a.png'
import pollThemeB from './assets/polls_theme_b.png'

type Props = {
  polls: Poll[]
}

type VoteSessionData = {
  poll: Poll
  theme: 'A' | 'B'
}

export function Polls({ polls }: Props) {
  const [poll, setPoll] = useState<Poll>()
  const [theme, setTheme] = useState<'A' | 'B'>()
  const [voted, setVoted] = useState(false)

  useEffect(() => {
    const voteSessionData = window.sessionStorage.getItem('poll')
    if (voteSessionData) {
      const data: VoteSessionData = JSON.parse(voteSessionData)
      setPoll(data.poll)
      setTheme(data.theme)
      setVoted(true)
    } else {
      setPoll(polls[Math.floor(Math.random() * polls.length)])
      setTheme(Math.random() > 0.5 ? 'A' : 'B')
    }
  }, [])

  const onVote = (id: number, option: number) => {
    fetch(`/api/polls/vote`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id, option }),
    }).then(async (res) => {
      if (res.status === 200) {
        const data = await res.json()

        poll!.options[0].percentage = data.options[0].percentage
        poll!.options[1].percentage = data.options[1].percentage

        const voteSessionData = JSON.stringify({ poll, theme })
        window.sessionStorage.setItem('poll', voteSessionData)
        setVoted(true)
      }
    })
  }

  return (
    <div className={clsx(theme && theme === 'A' && styles.themeA, theme && theme === 'B' && styles.themeB)}>
      <div className={styles.poll}>
        <div className={styles.content}>
          <div className={styles.main}>
            {voted ? (
              <div className={styles.topline}>Danke für deine Stimme!</div>
            ) : (
              <div className={styles.topline}>Jetzt abstimmen!</div>
            )}
            <h1 className={styles.name}>{poll?.name}</h1>
            <div className={styles.vote}>
              <div className={styles.options}>
                {poll?.options.map((option) => (
                  <button
                    key={option.id}
                    className={clsx(
                      styles.option,
                      voted && styles.optionVoted,
                      voted && theme === 'A' && styles.optionVotedThemeA,
                      voted && theme === 'B' && styles.optionVotedThemeB
                    )}
                    onClick={() => onVote(poll.id, option.id)}
                    disabled={voted}
                    style={voted ? { flexBasis: `${option.percentage}%` } : {}}
                  >
                    {voted && <div className={styles.optionPercentage}>{option.percentage}%</div>}
                    <div className={clsx(styles.optionName, voted && styles.optionNameVoted)}>{option.name}</div>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
        {theme && theme === 'A' && <Image src={pollThemeA} alt="" className={styles.image} />}
        {theme && theme === 'B' && <Image src={pollThemeB} alt="" className={styles.image} />}
      </div>
    </div>
  )
}
